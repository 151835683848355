import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.sms',
      search: 'sms.search_keyword',
      filter: {
        status: {
          label: 'sms.data.status',
          type: 'selection',
          // @ts-ignore
          options: (listMeta) => this.vm.$statustType,
          optionTextPrefix: 'sms.data.status',
        },
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        name: row.name,
        title: row.title,
      }),
      displayMode: 'table',
      displayModeTypes: ['table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
        { label: 'sms.data.send_at', key: 'send_at' },
      ],
      table: {
        data: <TableDataType>[
          {
            key: 'phone_number',
            label: 'sms.data.phone_number',
            type: 'text',
          },
          {
            key: 'status',
            label: 'sms.data.status',
            type: 'text',
            text: row => this.vm?.$t(`sms.data.status.${row.status}`),
          },
          {
            key: 'message',
            label: 'sms.data.message',
            type: 'text',
          },
          {
            key: 'send_at',
            label: 'sms.data.send_at',
            type: 'time',
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        delete: {
          targetLabel: row => row.name,
        },
      },
      dataAction: {
        update: {
          label: 'action.edit',
          route: row => ({ name: 'sms-update', params: { target: row.id } }),
          linkTarget: '_self',
        },
        delete: {
          removeTarget: row => row.name,
        },
      },
    }

  }
}

export default new listConfig()
